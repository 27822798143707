import React from "react"
import { Box, Image, Badge, Grid } from "@chakra-ui/core"
import { Link } from "gatsby"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImage from "../../../images/phxSermons.jpg"
import LoveSong from "../../../images/Love_Song.jpg"
import LoveSongs from "../../../images/LoveSongsSeries.jpg"
import Radical from "../../../images/radicalLectures.jpg"
import Growth from "../../../images/growthSeries.jpg"
import Christmas from "../../../images/christmasSeries.jpg"
import More from "../../../images/moreSermons.jpg"
import FearNot from "../../../images/fearnotSeries.jpg"
import TogetherisBetter from "../../../images/togetherSeries.jpg"
import HolidayOnItsHead from "../../../images/HolidaySeries.jpg"
import WhenPigsFly from "../../../images/whenPigsFly_series.jpg"
import WhenPeoplePray from "../../../images/whenPeoplePray_series.jpg"
import Colossians from "../../../images/ColossiansSeries.jpg"
import Searching from "../../../images/searchingSeries.jpg"
import PursuitofHappiness from "../../../images/POHSquareSeries.jpeg"
import NowWhat from "../../../images/nowWhatSeries.jpg"
import VisionSunday from "../../../images/visionSunday.jpg"
import INUWSeries from "../../../images/INUWSeries.jpg"
import SevenThings from "../../../images/SevenThings_Series.jpeg"
import Imperatives from "../../../images/Imperatives_Series.jpeg"
import ChristmasComes from "../../../images/ChristmasComesSquare.jpeg"
import Partnerships from "../../../images/PartnershipsTitle.jpg"
import CounterCulture from "../../../images/counterCulture_title.jpeg"
import ThePuzzle from "../../../images/ThePuzzle_square.jpg"
import Romans from "../../../images/Romans_Series.jpeg"
import Practice from "../../../images/practiceTitle.jpeg"
import JesusinGenesis from "../../../images/JesusinGenesis_Square.jpg"
import AWC from "../../../images/AWC_Square.jpg"
import LTD from "../../../images/LivingtheDream_Square.jpg"
import MMM from "../../../images/MMM_Square.jpg"
import TH from "../../../images/TH_Square.jpg"
import GH from "../../../images/GH_Square.jpg"
import TBT from "../../../images/TheBig10_Square.jpg"
import PerfectChristmas from "../../../images/PerfectChristmas_Square.jpg"
import CrossingRivers from "../../../images/CrossingRivers_Square.jpg"
import TheWeek from "../../../images/TWTCTW_Square.jpg"
import Rise from "../../../images/Easter2022.jpg"
import Hebrews from "../../../images/Hebrews_Square.jpg"
import WhyChurch from "../../../images/WhyChurch_Square.jpg"
import Relationships from "../../../images/Relationships_Square.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} - {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Sermons"
    SecondText="Check out our previous sermons here."
  >
    <SEO title="Sermons" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={WhyChurch}
        imageAlt="Why Church title image"
        sermonTitle="Why Church?"
        pastor="Present"
        sermonSeries="September 1st"
        sermonLink="/phoenix/sermons/why-church"
        badge="current series"
        badgeColor="teal"
      />
      <SermonBox
        imageUrl={Relationships}
        imageAlt="Relationships title image"
        sermonTitle="Relationships"
        pastor="August 25th"
        sermonSeries="August 4th"
        sermonLink="/phoenix/sermons/relationships"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews title image"
        sermonTitle="Hebrews"
        pastor="July 28th"
        sermonSeries="April 7th"
        sermonLink="/phoenix/sermons/hebrews"
      />
      <SermonBox
        imageUrl={TheWeek}
        imageAlt="The Week That Changed the World Sermon series image"
        sermonTitle="The Week That Changed the World"
        pastor="March 31st"
        sermonSeries="March 10th"
        sermonLink="/phoenix/sermons/the-week-that-changed-the-world"
      />
      <SermonBox
        imageUrl={CrossingRivers}
        imageAlt="Crossing Rivers image"
        sermonTitle="Crossing Rivers"
        pastor="March 3rd"
        sermonSeries="January 7th"
        sermonLink="/phoenix/sermons/crossing-rivers"
      />
      <SermonBox
        imageUrl={PerfectChristmas}
        imageAlt="Searching for the Perfect Christmas image"
        sermonTitle="Searching for the Perfect Christmas"
        pastor="December 24th"
        sermonSeries="December 3rd"
        sermonLink="/phoenix/sermons/the-perfect-christmas"
      />
      <SermonBox
        imageUrl={TBT}
        imageAlt="The Big 10 title image"
        sermonTitle="The Big 10"
        pastor="November 26th"
        sermonSeries="September 24th"
        sermonLink="/phoenix/sermons/the-big-ten"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits title image"
        sermonTitle="Greatest Hits"
        pastor="September 17th"
        sermonSeries="July 16th"
        sermonLink="/phoenix/sermons/greatest-hits"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness title image"
        sermonTitle="Touching Holiness"
        pastor="July 9th"
        sermonSeries="April 2nd"
        sermonLink="/phoenix/sermons/touching-holiness"
      />
      <SermonBox
        imageUrl={MMM}
        imageAlt="Marriage, Money and Minions title image"
        sermonTitle="Marriage, Money, and Minions"
        pastor="March 26th"
        sermonSeries="February 19th"
        sermonLink="/phoenix/sermons/marriage-money-minions"
      />
      <SermonBox
        imageUrl={LTD}
        imageAlt="Living the Dream title image"
        sermonTitle="Living the Dream"
        pastor="February 12th"
        sermonSeries="January 8th"
        sermonLink="/phoenix/sermons/living-the-dream"
      />
      <SermonBox
        imageUrl={AWC}
        imageAlt="A Winning Christmas title image"
        sermonTitle="A Winning Christmas"
        pastor="December 24th"
        sermonSeries="December 4th"
        sermonLink="/phoenix/sermons/a-winning-christmas"
      />
      <SermonBox
        imageUrl={JesusinGenesis}
        imageAlt="Jesus in Genesis title image"
        sermonTitle="Jesus in Genesis"
        pastor="November 27th"
        sermonSeries="October 2nd"
        sermonLink="/phoenix/sermons/jesus-in-genesis"
      />
      <SermonBox
        imageUrl={ThePuzzle}
        imageAlt="The Puzzle title image"
        sermonTitle="The Puzzle"
        pastor="September 25th"
        sermonSeries="September 4th"
        sermonLink="/phoenix/sermons/the-puzzle"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture title image"
        sermonTitle="Counter Culture"
        pastor="August 28th"
        sermonSeries="May 15th"
        sermonLink="/phoenix/sermons/counter-culture"
      />
      <SermonBox
        imageUrl={Partnerships}
        imageAlt="Partnerships title image"
        sermonTitle="Partnerships"
        pastor="May 8th"
        sermonSeries="April 24th"
        sermonLink="/phoenix/sermons/partnerships"
      />
      <SermonBox
        imageUrl={Rise}
        imageAlt="Easter at Cross Church Rise title image"
        sermonTitle="Easter at Cross Church: Rise"
        pastor="April 17th"
        sermonSeries="April 17th"
        sermonLink="/phoenix/sermons/easter-rise"
      />
      <SermonBox
        imageUrl={Practice}
        imageAlt="Romans Practice title image"
        sermonTitle="Romans: Practice"
        pastor="April 10th"
        sermonSeries="January 9th"
        sermonLink="/phoenix/sermons/romans-practice"
      />
      <SermonBox
        imageUrl={ChristmasComes}
        imageAlt="Christmas Comes title image"
        sermonTitle="Christmas Comes"
        pastor="December 20th"
        sermonSeries="December 6th"
        sermonLink="/phoenix/sermons/christmas-comes"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans series title image"
        sermonTitle="Romans: Principles"
        pastor="November 28th"
        sermonSeries="September 19th"
        sermonLink="/phoenix/sermons/romans"
      />
      <SermonBox
        imageUrl={Imperatives}
        imageAlt="Imperatives series title image"
        sermonTitle="Imperatives"
        pastor="September 12th"
        sermonSeries="August 22nd"
        sermonLink="/phoenix/sermons/imperatives"
      />
      <SermonBox
        imageUrl={SevenThings}
        imageAlt="Seven Things series title image"
        sermonTitle="Seven Things"
        pastor="August 15th"
        sermonSeries="July 4th"
        sermonLink="/phoenix/sermons/seven-things"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness series title image"
        sermonTitle="The Pursuit of Happiness"
        pastor="June 27th"
        sermonSeries="April 11th"
        sermonLink="/phoenix/sermons/the-pursuit-of-happiness"
      />
      <SermonBox
        imageUrl={Searching}
        imageAlt="Searching series title image"
        sermonTitle="Searching..."
        pastor="April 4th"
        sermonSeries="March 14th"
        sermonLink="/phoenix/sermons/searching"
      />
      <SermonBox
        imageUrl={LoveSongs}
        imageAlt="Love Songs series title image"
        sermonTitle="Love Songs"
        pastor="March 7th"
        sermonSeries="February 14th"
        sermonLink="/phoenix/sermons/lovesongs"
      />
      <SermonBox
        imageUrl={INUWSeries}
        imageAlt="If Not Us Who? series title image"
        sermonTitle="If Not Us, Who?"
        pastor="February 7th"
        sermonSeries="January 10th"
        sermonLink="/phoenix/sermons/ifnotus"
      />
      <SermonBox
        imageUrl={VisionSunday}
        imageAlt="Vision Sunday series title image"
        sermonTitle="Vision Sunday"
        pastor="January 3rd"
        sermonSeries="January 3rd"
        sermonLink="/phoenix/sermons/vision"
      />
      <SermonBox
        imageUrl={HolidayOnItsHead}
        imageAlt="Holiday on its Head series title image"
        sermonTitle="Holiday on its Head"
        pastor="December 27th"
        sermonSeries="October 25th"
        sermonLink="/phoenix/sermons/holiday-on-its-head"
      />
      <SermonBox
        imageUrl={TogetherisBetter}
        imageAlt="Together is Better series title image"
        sermonTitle="Together is Better"
        pastor="October 18th"
        sermonSeries="September 13th"
        sermonLink="/phoenix/sermons/together-is-better"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? title image"
        sermonTitle="Now What?"
        pastor="September 6th"
        sermonSeries="July 19th"
        sermonLink="/phoenix/sermons/now-what"
      />
      <SermonBox
        imageUrl={Colossians}
        imageAlt="Colossians title image"
        sermonTitle="Colossians"
        pastor="July 12th"
        sermonSeries="May 17th"
        sermonLink="/phoenix/sermons/colossians"
      />
      <SermonBox
        imageUrl={WhenPigsFly}
        imageAlt="When Pigs Fly Title image"
        sermonTitle="When Pigs Fly"
        pastor="May 10th"
        sermonSeries="April 12th"
        sermonLink="/phoenix/sermons/when-pigs-fly"
      />
      <SermonBox
        imageUrl={WhenPeoplePray}
        imageAlt="When People Pray title image"
        sermonTitle="When People Pray"
        pastor="April 5th"
        sermonSeries="March 22nd"
        sermonLink="/phoenix/sermons/when-people-pray"
      />
      <SermonBox
        imageUrl={FearNot}
        imageAlt="Fear Not title image"
        sermonTitle="Fear Not"
        pastor="March 15th"
        sermonSeries="March 15th"
        sermonLink="/phoenix/sermons/fear-not"
      />
      <SermonBox
        imageUrl={LoveSong}
        imageAlt="Love Song title image"
        sermonTitle="Love Song"
        pastor="March 8th"
        sermonSeries="February 16th"
        sermonLink="/phoenix/sermons/love-song"
      />
      <SermonBox
        imageUrl={Radical}
        imageAlt="radical lectures image"
        sermonTitle="Radical Lectures"
        sermonSeries="January 19th"
        sermonLink="/phoenix/sermons/radical-lectures"
        pastor="February 9th"
      />
      <SermonBox
        imageUrl={Growth}
        imageAlt="Growth Image"
        sermonTitle="Growth"
        sermonSeries="January 5th"
        sermonLink="/phoenix/sermons/growth"
        pastor="January 12th"
      />
      <SermonBox
        imageUrl={Christmas}
        imageAlt="Christmas in Canaan"
        sermonTitle="Christmas in Canaan"
        sermonLink="/phoenix/sermons/christmas-in-canaan"
        sermonSeries="December 1st"
        pastor="December 22nd"
      />
      <SermonBoxExternal
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View Past Sermons"
        sermonLink="https://www.youtube.com/playlist?list=PLMj_eBpdMTxe0MBf0NlvotSMkyuE5ysQI"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
